const hexComponentToColorComponent = hex => parseInt(hex.length === 1 ? hex.repeat(2) : hex, 16)

const hexToNormalized = hex => {
  if (hex.length < 4 || hex.length > 9 || hex.length === 8) {
    throw new Error(`invalid hex value '${hex}', only supports 3,4,6 or 8 hex digits`)
  }

  const components = hex.length === 4 || hex.length === 5
    ? hex.substring(1).split('').map(hexComponentToColorComponent)
    : hex.substring(1).match(/.{2}/g).map(hexComponentToColorComponent)

  if (components.length === 4) {
    components[3] = components[3] / 255
  } else {
    components.push(1)
  }

  return components
}

const rgbaStringToNormalized = rgbaString => {
  const components = rgbaString.split(/[()]/)[1].split(',').map(x => parseFloat(x.trim()))

  if (components.length === 3) {
    components.push(1)
  }

  return components
}

function normalizeColor (color) {
  if (Array.isArray(color) && color.length === 4) {
    return color
  }

  switch (color[0]) {
  case '#': {
    return hexToNormalized(color)
  }
  case 'r': {
    return rgbaStringToNormalized(color)
  }
  default:
    throw new Error(`invalid color: '${color}', only hex or rgb(a) supported`)
  }
}

export function toRgba (color) {
  const [r, g, b, a] = normalizeColor(color)

  return `rgba(${r},${g},${b},${a})`
}

export function toHexAbgr (color, a = 'FF') {
  const hex = toHex(color)

  const r = hex.substring(1, 3)
  const g = hex.substring(3, 5)
  const b = hex.substring(5, 7)
  return `#${a}${b}${g}${r}`
}

export function toHex (color) {
  const rgba = normalizeColor(color)

  rgba[3] = Math.round(rgba[3] * 255)

  const relevantChannels = rgba[3] === 255 ? rgba.slice(0, 3) : rgba

  return '#' + relevantChannels.map(x => ('0' + x.toString(16).toUpperCase()).slice(-2)).join('')
}

export function withAlpha (color, opacity) {
  color = normalizeColor(color)

  const [r, g, b] = color.slice(0, 3)

  return `rgba(${r},${g},${b},${opacity})`
}

const luminance = rgba => {
  const [r, g, b] = rgba.slice(0, 3).map(c => c <= 10.0164 ? c / 3294.6 : Math.pow((c + 0.055) / 269.025, 2.4))
  return 0.2126 * r + 0.7152 * g + 0.0722 * b
}

const blackOrWhite = rgbaBackgroundColor => luminance(rgbaBackgroundColor) > 0.179
  ? 'rgb(0,0,0)'
  : 'rgb(255,255,255)'

export const readableTextColor = backgroundColor => blackOrWhite(normalizeColor(backgroundColor))

const clamp = (value, [min, max]) => Math.max(min, Math.min(max, value))
const lerp = (a, b, t) => a + t * (b - a)

export const desaturate = (color, factor = 1) => {
  const [r, g, b, a] = normalizeColor(color)

  const gray = Math.floor(0.5 * (Math.min(r, g, b) + Math.max(r, g, b)))

  const mix = channel => lerp(channel, gray, factor)

  return `rgba(${mix(r)},${mix(g)},${mix(b)},${a})`
}

export function interpolate (color1, color2, t = 0.5) {
  const [components1, components2] = [color1, color2].map(normalizeColor)

  const blend = i => lerp(components1[i], components2[i], t)

  const [r, g, b, a] = components1.map((x, i) => i < 3 ? Math.round(blend(i)) : blend(i))

  return `rgba(${r},${g},${b},${a})`
}

export function interpolateRegularGradient (colors, t = 0.5) {
  t = clamp(t, [0, 1])

  // NOTE account for inclusive end of last color interval
  if (t === 1) return colors[colors.length - 1]

  const rawBin = Math.floor(t * (colors.length - 1))
  const bin = clamp(rawBin, [0, colors.length - 2])

  const renormalized = t % (1 / (colors.length - 1))

  return interpolate(colors[bin], colors[bin + 1], renormalized)
}
