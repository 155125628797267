<template lang="html">
  <div class="dashboard-header">
    <h3>
      <IxRes>dlgCert.dashboard.header</IxRes>
    </h3>
    <div class="description">
      <IxRes>dlgCert.dashboard.description</IxRes>
    </div>

    <HelpPrompt class="help">
      <DashboardHelp />
    </HelpPrompt>

    <div class="submit-section">
      <IxButton
        large submit
        icon="paper-plane" disabled
      >
        <IxRes>dlgCert.dashboard.buttons.sendData</IxRes>
      </IxButton>
      <DashboardStatusLine warning>
        <IxRes>dlgCert.dashboard.hints.missingData</IxRes>
      </DashboardStatusLine>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

import IxButton from '@components/IxButton'
import IxRes from '@components/IxRes'

import HelpPrompt from './HelpPrompt'
import DashboardHelp from './DashboardHelp'
import DashboardStatusLine from './DashboardStatusLine'

export default {
  components: {
    IxButton,
    IxRes,
    HelpPrompt,
    DashboardHelp,
    DashboardStatusLine
  },
  computed: {
    year () {
      return moment().year()
    }
  }
}
</script>

<style lang="scss" scoped>
.dashboard-header {
  display: flex;
  flex-direction: column;
}

h3 {
  text-transform: uppercase;
  margin: 0 0 5px 0;
}

.help {
  margin: 0.25em 0;
}

.submit-section {
  display: flex;
  margin-top: auto;

  > :not(:first-child) {
    margin-left: 0.5em;
  }
}
</style>
