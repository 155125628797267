<template lang="html">
  <div class="pillar-header" :style="{backgroundColor: color, color: textColor}">
    <slot />
  </div>
</template>

<script>
import {readableTextColor} from '@helpers/color'

export default {
  props: {
    color: String
  },
  computed: {
    textColor () {
      return readableTextColor(this.color)
    }
  }
}
</script>

<style lang="scss" scoped>
.pillar-header {
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 1.5em;
  text-transform: uppercase;

  border-radius: 5px 0 0 5px;
  padding: 1.6em 0.8em;
  margin-bottom: 3rem;
}
</style>
