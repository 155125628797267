<template lang="html">
  <div class="pillar-tile">
    <slot />

    <IxButton
      :colored="status !== 'finished'"
      class="button"
      @click="$router.push({name: pillar, params: {year}})"
    >
      <IxRes>dlgCert.dashboard.buttons.tile_{{ status }}</IxRes>
    </IxButton>
  </div>
</template>

<script>
import IxButton from '@components/IxButton'

export default {
  components: {
    IxButton
  },
  props: {
    status: {
      type: String,
      required: true,
      validator: x => ['empty', 'started', 'finished'].includes(x)
    },
    pillar: String,
    year: Number
  }
}
</script>

<style lang="scss" scoped>
.pillar-tile {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  border-left: 1px solid #e8e8e8;

  &:nth-child(4n+8) {
    border-radius: 0 5px 5px 0;
  }

  padding: 1em 3em;
  margin-bottom: 3rem;
}

.button {
  margin-top: auto;
}
</style>
