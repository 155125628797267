<template lang="html">
  <div class="dashboard-layout">
    <div class="header">
      <DashboardHeader />
      <wip v-if="$can('See.Frs.ExperimentalFeatures')">
        <AccessControlCard />
      </wip>
    </div>

    <h2>
      {{ selectedCompany.name }}
    </h2>

    <a href="#" data-ajax="false" @click.stop.prevent="$router.push({name: 'companySelection', params: {orgUnitId: $route.params.orgUnitId}})">
      <IxRes>dlgCert.buttons.backToCompanySelection</IxRes>
    </a>

    <div v-if="status != null" class="pillar-grid">
      <div />
      <div v-for="year in years" :key="year" class="year">
        <div>{{ $i18n.format(year, 'harvestyear') }}</div>
        <div>
          <IxButton
            large
            icon="eye"
            @click="$router.push({name: 'certificatePreview', params: {...$route.params, year}})"
          >
            <IxRes>dlgCert.dashboard.buttons.showCertificatePreview</IxRes>
          </IxButton>
        </div>
      </div>
      <template v-for="pillar in pillars">
        <DashboardPillarLabel :key="`${pillar}-header`" :color="colors[pillar]">
          <IxRes>dlgCert.headers.{{ pillar }}</IxRes>
        </DashboardPillarLabel>
        <DashboardPillarTile
          v-for="year in years" :key="`${pillar}-${year}`"
          :status="status[pillar][year].state"
          :pillar="pillar"
          :year="year"
        >
          <DashboardStatus :status="status[pillar][year]" />
        </DashboardPillarTile>
      </template>
    </div>

    <BsModal v-model="showFirstTimeModal">
      <template #title>
        <span>
          <i class="fa fa-question-circle" />
          <IxRes>dlgCert.dashboard.modals.title</IxRes>
        </span>
      </template>

      <DashboardHelpFirstTime />

      <template #footer>
        <IxButton
          large icon="arrow-circle-right"
          submit
          @click="showFirstTimeModal = false"
        >
          <IxRes>dlgCert.modal.buttons.next</IxRes>
        </IxButton>
      </template>
    </BsModal>
  </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import {mapGetters} from 'vuex'

import {notifications} from 'src/js/infrastructure'

import {mapFormFields} from '@helpers/vuex'

import BsModal from '@components/bootstrap/BsModal'
import IxButton from '@components/IxButton'

import DashboardHeader from './widgets/DashboardHeader'
import AccessControlCard from './widgets/AccessControlCard'
import DashboardPillarLabel from './widgets/DashboardPillarLabel'
import DashboardPillarTile from './widgets/DashboardPillarTile'
import DashboardStatus from './widgets/DashboardStatus'
import DashboardHelpFirstTime from './widgets/DashboardHelpFirstTime'

import PermissionMixin from '@mixins/PermissionMixin'

export default {
  components: {
    BsModal,
    IxButton,
    DashboardHeader,
    AccessControlCard,
    DashboardPillarLabel,
    DashboardPillarTile,
    DashboardHelpFirstTime,
    DashboardStatus
  },
  mixins: [
    PermissionMixin
  ],
  data () {
    return {
      status: null
    }
  },
  computed: {
    ...mapFormFields('dlgCert', [
      'seenFirstTimeHint'
    ]),
    ...mapGetters('dlgCert', [
      'orgUnits'
    ]),
    selectedCompany () {
      return this.orgUnits.find(x => x.id === this.$route.params.orgUnitId)
    },
    showFirstTimeModal: {
      get () {
        return !this.seenFirstTimeHint
      },
      set (value) {
        this.seenFirstTimeHint = !value
      }
    },
    years () {
      const year = moment().year()
      return [
        year - 2,
        year - 1,
        year
      ]
    },
    pillars () {
      return [
        'ecology',
        'economy',
        'social',
        'management',
        'frs'
      ]
    },
    placeholderStatus () {
      return this.pillars.reduce((lookup, pillar) => {
        lookup[pillar] = {}
        return this.years.reduce((lookup, year) => {
          const x = Math.random()
          const status = x < 0.33
            ? 'fresh'
            : x < 0.66
              ? 'dirty'
              : 'finished'

          lookup[pillar][year] = status
          return lookup
        }, lookup)
      }, {})
    },
    colors () {
      return {
        ecology: '#58a942',
        economy: '#e1944f',
        social: '#f7ca18',
        management: '#7bd4fa',
        frs: '#141b33'
      }
    }
  },
  async created () {
    try {
      const response = await axios.get(`/api/v2/dlg-cert/${this.$route.params.orgUnitId}/state/dashboard`)
      this.status = response.data
    } catch (error) {
      this.error = true
      console.error(error)
      notifications.error('dashboard failed to load')
    }
  }
}
</script>

<style lang="scss" scoped>
.dashboard-layout {
  display: flex;
  flex-direction: column;
  padding-top: 1em;
}

.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1em;
}

.pillar-grid {
  display: grid;
  grid-template:
    "dummy year1 year2 year3" auto
    "header tile tile tile" auto
    "header tile tile tile" auto
    "header tile tile tile" auto
    "header tile tile tile" auto / auto 1fr 1fr 1fr;

  > .year {
    font-size: 1.5em;
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 0.25em;
  }

  > :nth-child(n + 5) {
    position: relative;
    background-color: white;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      box-shadow: rgba(#000000, 0.4) 1px 2px 4px 0px;
    }
  }

  > :nth-child(4n + 5):after {
    border-radius: 5px 0 0 5px;
  }

  > :nth-child(4n + 8):after {
    border-radius: 0 5px 5px 0;
  }
}
::v-deep .modal-footer {
  text-align: left;
}
</style>
