<template lang="html">
  <div class="access-control-card">
    <div class="header">
      <FontAwesomeIcon :icon="icons.header" />
      Zugangsverwaltung
    </div>
    <span>3 Zugänge eingerichtet</span>
    <span>1 zusätzliches Modul freigeschaltet</span>
    <IxButton class="button" disabled>
      <template #icon>
        <FontAwesomeIcon :icon="icons.button" />
      </template>
      Zugänge &amp; Module verwalten
    </IxButton>
  </div>
</template>

<script>
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {faUsers, faPencilAlt} from '@fortawesome/free-solid-svg-icons'

import IxButton from '@components/IxButton'

export default {
  components: {
    FontAwesomeIcon,
    IxButton
  },
  computed: {
    icons () {
      return {
        header: faUsers,
        button: faPencilAlt
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.access-control-card {
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  margin-left: 0.75em;
  padding: 0.75em;
  box-shadow: rgba(#000000, 0.4) 1px 2px 4px 0px;
}

.header {
  display: flex;
  align-items: center;
  text-transform: uppercase;
  margin-bottom: 0.5em;

  > :first-child {
    margin-right: 0.5em;
  }
}

.button {
  margin-top: 1em;
}
</style>
