<template lang="html">
  <span>Dashboard help (first time only)</span>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
</style>
