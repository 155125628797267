<template lang="html">
  <div>
    <DashboardStatusLine v-if="status.state === 'finished'">
      Alle Bereiche vollständig
    </DashboardStatusLine>
    <DashboardStatusLine v-if="status.missing > 0" warning>
      {{ status.missing }} Warnungen vorhanden
    </DashboardStatusLine>
    <DashboardStatusLine v-if="status.fresh > 0" warning>
      {{ status.fresh }} leere Bereiche
    </DashboardStatusLine>
    <DashboardStatusLine v-if="status.finished > 0">
      {{ status.finished }} Bereiche vollständig
    </DashboardStatusLine>
  </div>
</template>

<script>
import DashboardStatusLine from './DashboardStatusLine'

export default {
  components: {
    DashboardStatusLine
  },
  props: {
    status: Object
  }
}
</script>

<style lang="scss" scoped>
</style>
